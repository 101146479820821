import { LOGIN_SUCCESS, LOGOUT, FORGOT_SUCCESS, RESET_PASSWORD, OTP_SUCCESS } from "../actions/types"

const initialState = {
    token: '',
    isLoggedIn: false,
    data:'',
    password:'',
    email:''
}

export default function (state = initialState, action) {
    const { type, payload, email, password } = action;
    switch (type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                ...payload,
                email: email,
                password: password
            };
        case OTP_SUCCESS:
            return {
                ...state,
                ...payload,
                email: email,
                password: password,
                isLoggedIn: true,
            };
        case LOGOUT:
            return {
                ...state,
                ...action.payload,
                isLoggedIn: false,
            };
        case FORGOT_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isLoggedIn: false,
            };
        case RESET_PASSWORD:
            return {
                ...state,
                ...action.payload,
                isLoggedIn: false,
            };
        default:
            return state
    }
}